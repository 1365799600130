import React from "react";
import theme from "theme";
import { Theme, Image, Box, Section, Text, Button, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Menu, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
import { MdRoom } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about-us"} />
		<Helmet>
			<title>
				Venta-Trans Europe - Freight Forwarding Company
			</title>
			<meta name={"description"} content={"Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of companies throughout Europe and CIS since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the high-quality and low-cost service that we provide."} />
			<meta property={"og:title"} content={"Venta-Trans Europe - Freight Forwarding Company"} />
			<meta property={"og:description"} content={"Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of companies throughout Europe and CIS since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the high-quality and low-cost service that we provide."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/og_img.jpg?v=2021-04-21T14:38:42.609Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/favicon-32x32.png?v=2021-04-22T09:26:54.780Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<meta name={"robots"} content={"noindex,nofollow"} />
		</Helmet>
		<Section font="--headline1" quarkly-title="SectionHeader">
			<Box
				display="flex"
				padding="12px 0"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="column"
			>
				<Image
					width="30%"
					height="64px"
					padding="0px 10px 0px 10px"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/vte_logo_en2.png?v=2021-01-14T09:28:54.706Z) 0% 0% /auto repeat scroll padding-box"
					src="https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/vte_logo_en2.png?v=2021-01-14T09:28:54.706Z"
				/>
				<Box background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/vte_logo_en2.png?v=2021-01-14T09:28:54.706Z) 0% 0% /auto no-repeat scroll padding-box" />
				<Menu
					display="flex"
					justify-content="center"
					font="--base"
					font-weight="700"
					md-flex-direction="column"
					md-align-items="center"
				>
					<Override slot="item" padding="6px" />
					<Override slot="item-404" display="none" />
					<Override slot="link-about-us" href="#about" />
					<Override slot="link" text-decoration="none" color="--dark" padding="6px 12px" />
					<Override slot="link-active" color="--primary" />
				</Menu>
			</Box>
		</Section>
		<Section padding="100px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Components.Source />
			<Components.Video />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				About Us
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Hi! I'm a paragraph. Click here to add your own text and edit me. It’s a piece of cake. I’m a great space for you to tell a story and let your site visitors know more about you. Talk about your business and what products and services you offer. Share how you came up with the idea for your company and what makes you different from your competitors. Make your business stand out and show your visitors who you are.
			</Text>
			<Button font="--lead" margin="20px">
				Button
			</Button>
		</Section>
		<Section
			quarkly-title="SectionRegionalDevelop"
			padding="60px 0"
			sm-padding="40px 0"
			min-height="400px"
			sm-min-height="auto"
			background="--color-fon2"
			display="flex"
			flex-direction="column"
		>
			<Text
				as="h1"
				margin="8px 0px 32px"
				font="--headline2"
				md-font="--headline2"
				color="--dark"
				max-width="850px"
			>
				REGIONAL DEVELOPMENT
			</Text>
			<Stack margin-top="auto" color="--grey" font="--base">
				<StackItem width="20%" md-width="50%" sm-width="100%">
					<Override slot="StackItemContent" display="block" />
					<Text font="--headline2" color="--fon" margin="0px" text-align="center">
						2010
					</Text>
					<Icon
						category="md"
						icon={MdRoom}
						margin="0px auto 0px auto"
						color="--green"
						size="48px"
					/>
					<Text margin="0px" color="--text" text-align="center" font="--lead">
						CZECH REPUBLIC
					</Text>
				</StackItem>
				<StackItem width="20%" md-width="50%" sm-width="100%" margin="70px 0px 0px 0px">
					<Override slot="StackItemContent" display="block" />
					<Text margin="0px" text-align="center" font="--headline2" color="--fon">
						2013
					</Text>
					<Icon
						margin="0px auto 0px auto"
						color="--green"
						size="48px"
						category="md"
						icon={MdRoom}
					/>
					<Text margin="0px" color="--text" text-align="center" font="--lead">
						POLAND
					</Text>
				</StackItem>
				<StackItem md-width="50%" sm-width="100%" width="20%">
					<Override slot="StackItemContent" display="block" />
					<Text margin="0px" text-align="center" font="--headline2" color="--fon">
						2016
					</Text>
					<Icon
						category="md"
						icon={MdRoom}
						margin="0px auto 0px auto"
						color="--green"
						size="48px"
					/>
					<Text margin="0px" color="--text" text-align="center" font="--lead">
						RUSSIA
					</Text>
				</StackItem>
				<StackItem width="20%" md-width="50%" sm-width="100%" margin="70px 0px 0px 0px">
					<Override slot="StackItemContent" display="block" />
					<Text color="--fon" margin="0px" text-align="center" font="--headline2">
						2017
					</Text>
					<Icon
						category="md"
						icon={MdRoom}
						margin="0px auto 0px auto"
						color="--green"
						size="48px"
					/>
					<Text margin="0px" color="--text" text-align="center" font="--lead">
						SLOVENIA
					</Text>
				</StackItem>
				<StackItem width="20%" md-width="50%" sm-width="100%">
					<Override slot="StackItemContent" display="block" />
					<Text margin="0px" text-align="center" font="--headline2" color="--fon">
						2018
					</Text>
					<Icon
						category="md"
						icon={MdRoom}
						margin="0px auto 0px auto"
						color="--green"
						size="48px"
					/>
					<Text font="--lead" margin="0px" color="--text" text-align="center">
						THE
NETHERLANDS
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Section
			display="flex"
			flex-direction="column"
			padding="60px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="--color-light"
		>
			<Components.Animation
				delay="0.5s"
				duration="1s"
				iteration="once"
				animation="← Slide In"
				trigger="Onload"
				test={false}
				timingFunction="linear"
			>
				<Text
					margin="0px 0px 8px 0px"
					font="--base"
					text-transform="uppercase"
					letter-spacing="1px"
					max-width="850px"
					color="--grey"
				>
					accreditations
				</Text>
			</Components.Animation>
			<Stack color="--grey" font="--base">
				<StackItem width="50%" md-width="100%">
					<Text
						margin="0px"
						font="--headline1"
						md-font="--headline2"
						color="--dark"
						max-width="850px"
						as="h1"
					>
						ISO
					</Text>
				</StackItem>
				<StackItem width="50%" md-width="100%">
					<Text margin="0px" color="--fon" font="--headline3">
						ISO 9001 : 2021
& 
ISO ICE 27001 : 2021
					</Text>
				</StackItem>
			</Stack>
			<Box />
			<Image width="64px" height="64px" display="inline-grid" />
			<Image width="64px" height="64px" display="inline-grid" />
		</Section>
		<RawHtml>
			<style place={"endOfBody"} rawKey={"608142d6ab4dd832c0755f1a"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
			<script src={"https://www.googletagmanager.com/gtag/js?id=G-118BTS02HM"} async={true} place={"endOfHead"} rawKey={"6082f9b76cd311d618e2da67"} />
			<script place={"endOfHead"} rawKey={"6082f9d0a29b8aa4421cb038"}>
				{"window.dataLayer = window.dataLayer || [];\nfunction gtag(){dataLayer.push(arguments);}\ngtag('js', new Date());\ngtag('config', 'G-118BTS02HM');"}
			</script>
			<script src={"https://fast.wistia.com/embed/medias/ivn4prep36.jsonp"} async={true} place={"endOfHead"} rawKey={"6083012406cc56de18db89d6"} />
			<script async={true} src={"https://fast.wistia.com/assets/external/E-v1.js"} place={"endOfHead"} rawKey={"608301398ecb4f30a2c73278"} />
		</RawHtml>
	</Theme>;
});